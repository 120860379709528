.highlighted-row {
  background-color: #dcdcdc; /* Replace with your desired background color */
  /* Add any other styling properties as needed */
}

.highlighted-row-service {
  background-color: #f5d481; /* Replace with your desired background color */
  /* Add any other styling properties as needed */
}

.highlighted-row-damage {
  background-color: #ff7979; /* Replace with your desired background color */
  /* Add any other styling properties as needed */
}